import React                    from "react";
import DialogWindow             from "./DialogWindow";
import PropTypes                from "prop-types";
import {setCookie}              from "../utils/CommonUtils";
import Flux                     from "../flux/Flux";
import Translations             from "../utils/Translations";
import { getCommonTranslation } from '../utils/TranslationHelper';

class LangRedirectPopup extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {show: true};

		this.onClickYes = this.onClickYes.bind(this);
		this.onClickNo = this.onClickNo.bind(this);
	}

	componentDidMount() {
		// Only show once
		setCookie(this.props.cookieName, '0', 30, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
	}

	onClickNo() {
		this.setState({show: false});
	}

	onClickYes() {
		window.location.href = this.props.redirectUrl;
	}

	render() {
		const text1 = getCommonTranslation('surveyLangRedirectInfo');
		const text2 = getCommonTranslation('surveyLangRedirectQuestion');

		const button1 = {
			text:    Translations.get('BtnYes'),
			active:  true,
			onClick: this.onClickYes,
		};

		const button2 = {
			text:    Translations.get('BtnNo'),
			active:  false,
			onClick: this.onClickNo,
		};

		const bottom = 15;
		const right  = Flux.Guest.isLoggedIn() ? 90 : 20;

		let content = <div />;
		if (this.state.show) {
			content = <DialogWindow textRow1={text1}
			                        textRow2={text2}
			                        button1={button1}
			                        button2={button2}
			                        right={right}
			                        bottom={bottom}
			                        shadows={true}
			          />;
		}

		return content;
	}
}

LangRedirectPopup.propTypes = {
	cookieName:  PropTypes.string,
	redirectUrl: PropTypes.string,
};

export default LangRedirectPopup;