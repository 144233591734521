import React     from "react";
import PropTypes from "prop-types";
import { generateKey } from "../utils/CommonUtils";
class DialogWindow extends React.PureComponent {

	constructor(props) {
		super(props);
	}

	render() {
		const content = [];

		let closeButton = '';
		if (this.props.onClose) {
			closeButton = (
				<span className="dialog-window-header-close" onClick={this.props.onClose}>
					<i className="icon -icon-close-full dialog-window-header-close-icon" />
				</span>);
		}

		const header = (
			<div 
				key={generateKey("dialog-window-header", content.length)} 
				className="dialog-window-header"
			>
				{closeButton}
			</div>);
		content.push(header);

		let textRow1 = '';
		if (this.props.textRow1) {
			let textRow2ClassSuffix = '';
			if (!this.props.onClose) {
				textRow2ClassSuffix = ' -no-exit';
			}
			textRow1 = <div className={"dialog-window-body-text1" + textRow2ClassSuffix}>{this.props.textRow1}</div>;
		}

		let textRow2 = '';
		if (this.props.textRow2) {
			textRow2 = <div className="dialog-window-body-text2">{this.props.textRow2}</div>;
		}

		const body = (
			<div
				key={generateKey("dialog-window-body", content.length)}
				className="dialog-window-body">
				{textRow1}{textRow2}
			</div>);
		content.push(body);

		let button1 = '';
		let button2 = '';
		if (this.props.button1 && this.props.button2) {
			let button1ClassSuffix = '';
			if (this.props.button1.active) {
				button1ClassSuffix = ' -is-active';
			}
			let button2ClassSuffix = '';
			if (this.props.button2.active) {
				button2ClassSuffix = ' -is-active';
			}
			button1 = (
				<button className={"dialog-window-footer-button -first" + button1ClassSuffix}
				        onClick={this.props.button1.onClick}
				>{this.props.button1.text}
				</button>);
			button2 = (
				<button className={"dialog-window-footer-button -second" + button2ClassSuffix}
				        onClick={this.props.button2.onClick}
				>{this.props.button2.text}
				</button>);
		} else if (this.props.button1) {
			let button1ClassAffix = '';
			if (this.props.button1.active) {
				button1ClassAffix = ' -is-active';
			}
			button1 = (
				<button className={"dialog-window-footer-button -only" + button1ClassAffix}
				        onClick={this.props.button1.onClick}
				>{this.props.button1.text}
				</button>);
		}

		const footer = (
			<div 
				key={generateKey("dialog-window-footer", content.length)}
				className="dialog-window-footer">
				{button1}{button2}
			</div>);
		content.push(footer);

		const bottom      = this.props.bottom + 'px';
		const right       = this.props.right + 'px';
		const windowStyle = {bottom: bottom, right: right};

		let mainWindowClassSuffix = '';
		if (this.props.shadows) {
			mainWindowClassSuffix = ' -shadows';
		}

		return (
			<div style={windowStyle} className={"dialog-window-main" + mainWindowClassSuffix}>
				{content}
			</div>);
	}

}

DialogWindow.propTypes = {
	button1:  PropTypes.object,
	button2:  PropTypes.object,
	textRow1: PropTypes.string,
	textRow2: PropTypes.string,
	onClose:  PropTypes.func,
	right:    PropTypes.number,
	bottom:   PropTypes.number,
	shadows:  PropTypes.bool,
	isMobile: PropTypes.bool,
};

DialogWindow.defaultProps = {
	button1:  null,
	button2:  null,
	textRow1: null,
	textRow2: null,
	onClose:  null,
	bottom:   20,
	right:    100,
	shadows:  false,
	isMobile: false,
};

export default DialogWindow;
